<template>
  <va-card title="Request">
    <div class="mb-3">
      <h2 class="display-4 mb-1">Page Title</h2>
      <span>{{ pageTitle }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Page Url</h2>
      <a target="_blank" :href="pageUrl">{{ pageUrl }}</a>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Name</h2>
      <span>{{ name }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Phone</h2>
      <span>{{ phone }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Email</h2>
      <span>{{ email }}</span>
    </div>

    <div v-if="comment" class="mb-3">
      <h2 class="display-4 mb-1">Comment</h2>
      <span>{{ comment }}</span>
    </div>

    <div class="mb-3" v-if="created_at">
      <h2 class="display-4 mb-1">Created at</h2>
      <span>{{ created_at }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      pageTitle: '',
      pageUrl: '',
      name: '',
      phone: '',
      email: '',
      comment: '',
      created_at: '',
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-request/${this.$attrs.id}?lang=${this.locale}`)
        .then(response => {
          this.name = response.data.name
          this.phone = response.data.phone
          this.email = response.data.email
          this.created_at = response.data.created_at
          this.pageTitle = response.data.page_title
          this.pageUrl = response.data.page_url
          this.comment = response.data.comment
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
